<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("AUTHORISE_PAYROLL")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Authorise Payroll Routine",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  async mounted() {
    this.loading = true;
    await this.getLocations();
    this.fetchPMY();
    await this.fetchPayrollRoutine();
    this.loading = false;
  },
  data() {
    return {
      title: "Authorise Payroll Routine",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Authorise Payroll Routine",
          active: true,
        },
      ],
      routineRun: false,
      pay: [],
      selectedLocations: [],
      locationIds: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      fields: [
        "#",
        { key: "sn", label: "S/n", sortable: true },
        { key: "locationName", label: "Location", sortable: true },
        { key: "locationTotalGross", label: "Total Gross", sortable: true },
        {
          key: "locationTotalDeduction",
          label: "Total Deduction",
          sortable: true,
        },
        { key: "locationTotalNet", label: "Total Net", sortable: true },
        {
          key: "locationEmployeesCount",
          label: "Total Employees",
          sortable: true,
        },
        { key: "month", label: "Month", sortable: true },
        { key: "year", label: "Year", sortable: true },
      ],
      pmyMonth: null,
      pmyYear: null,
      payrollLocations: null,
      payrollLocation: null,
      comment: null,
      loading: false,
    };
  },
  methods: {
    selectLocations(items) {
      this.selectedLocations = items;
    },
    selectAllRows() {
      this.$refs.payrollSummaryTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.payrollSummaryTable.clearSelected();
    },
    getLocations() {
      this.apiGet(this.ROUTES.location, "Get Locations Error").then((res) => {
        this.payrollLocations = [
          { value: null, text: "Please select a location" },
        ];
        const { data } = res;
        data.forEach((location) => {
          this.payrollLocations.push({
            value: location.location_id,
            text: `${location.location_name} - (${location.l_t6_code} ) `,
          });
        });
      });
    },
    fetchPMY() {
      this.apiGet(
        this.ROUTES.payrollMonthYear,
        "Get Payroll Month & Year Error"
      ).then((res) => {
        if (res.data) {
          const { pym_year, pym_month } = res.data;
          this.pmyMonth = pym_month;
          this.pmyYear = pym_year;
        }
      });
    },
    async fetchPayrollRoutine() {
      let url = `${this.ROUTES.salary}/pull-confirmed-salary-routine-locations`;
      await this.apiGet(url, "Fetch Payroll Routine Error")
        .then((res) => {
          this.routineRun = true;
          const { data } = res;
          data.forEach((pay, index) => {
            this.pay[index] = { sn: ++index, ...pay };
          });
          this.totalRows = this.pay.length;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      let locationId = row.locationId;
      this.$router.push({
        name: "authorise-employee-emolument",
        params: { locationId },
      });
      this.$refs["payrollSummaryTable"].clearSelected();
    },
    resetForm() {
      this.payrollLocation = null;
    },
    authoriseSelected() {
      this.locationIds = [];
      this.selectedLocations.forEach((location) => {
        this.locationIds.push(location.locationId);
      });

      if (!this.locationIds.length) {
        this.apiFormHandler("Please select at least one location");
        return;
      }

      if (!this.comment) {
        this.apiFormHandler(
          "A comment is required to authorise payroll routine."
        );
        return;
      }

      const data = {
        pmyl_location_id: this.locationIds,
        pmyl_comment: this.comment,
      };
      const url = `${this.ROUTES.salary}/authorise-salary-routine`;
      this.apiPost(url, data, "Authorise Payroll Routine").then((res) => {
        this.apiResponseHandler(`${res.data}`, "Action Successful");

        setTimeout(function(){
          window.location.reload();
        }, 2000);

        /*this.$router
          .push({ name: "approve-payroll" })
          .then(() =>
            this.apiResponseHandler(`${res.data}`, "Action Successful")
          );*/

      });
    },
    unconfirmRoutine() {
      this.locationIds = [];
      this.selectedLocations.forEach((location) => {
        this.locationIds.push(location.locationId);
      });

      if (!this.locationIds.length) {
        this.apiFormHandler("Please select at least one location");
        return;
      }


      const data = {
        pmyl_location_id: this.locationIds
      };
      const url = `${this.ROUTES.salary}/unconfirm-salary-routine`;
      this.apiPost(url, data, "Un-confirm Payroll Routine").then((res) => {
        this.apiResponseHandler(`${res.data}`, "Action Successful");

        setTimeout(function(){
          window.location.reload();
        }, 2000);

      });
    },
    // unconfirmRoutine() {
    //   this.submitted = true;
    //
    //   const data = {
    //     pmyl_location_id: this.$route.params.locationId,
    //   };
    //
    //   const url = `${this.ROUTES.salary}/unconfirm-salary-routine`;
    //   this.apiPost(url, data, "Unconfirm Salary Routine").then((res) => {
    //     this.$router
    //       .push({ name: "process-payroll" })
    //       .then(() =>
    //         this.apiResponseHandler(`${res.data}`, "Action Successful")
    //       );
    //   });
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!--    <div class="d-flex justify-content-end mb-3">-->
    <!--      <b-button-->
    <!--        class="btn btn-danger mr-2"-->
    <!--        @click="$refs['unconfirm-routine'].show()"-->
    <!--        v-if="permissions.includes('UNDO_PAYROLL')"-->
    <!--      >-->
    <!--        <i class="mdi mdi-undo-variant mr-2"></i>-->
    <!--        Unconfirm Routine-->
    <!--      </b-button>-->
    <!--    </div>-->
    <scale-loader v-if="loading" />
    <div v-else>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="p-3 bg-light mb-4 d-flex justify-content-between">
                  <h5 class="font-size-14 mb-0">
                    Confirmed Payroll Summary For Payroll Period:
                    {{ (parseInt(pmyMonth) - 1) | getMonth }} {{ pmyYear }}
                  </h5>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0" v-if="pay.length">
                  <b-table
                    ref="payrollSummaryTable"
                    bordered
                    selectable
                    hover
                    :items="pay"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    select-mode="multi"
                    @row-selected="selectLocations"
                  >
                    <template #cell(#)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>
                    <template #cell(locationTotalGross)="row">
                      <p class="float-right mb-0">
                        {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                      </p>
                    </template>
                    <template #cell(locationTotalDeduction)="row">
                      <p class="float-right mb-0">
                        {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                      </p>
                    </template>
                    <template #cell(locationTotalNet)="row">
                      <p class="float-right mb-0">
                        {{ parseFloat(row.value.toFixed(2)).toLocaleString() }}
                      </p>
                    </template>

                    <template #cell(month)="row">
                      <p class="mb-0">
                        {{ (parseInt(row.value) - 1) | getMonth }}
                      </p>
                    </template>
                  </b-table>
                  <div>
                    <b-button
                      style="margin: 10px"
                      variant="primary"
                      size="sm"
                      @click="selectAllRows"
                    >
                      Select all</b-button
                    >

                    <b-button
                      style="margin: 10px"
                      variant="warning"
                      size="sm"
                      @click="clearSelected"
                    >
                      Clear Selection</b-button
                    >

                    <b-button
                      style="margin: 10px"
                      variant="success"
                      size="sm"
                      @click="$refs['authorise-routine'].show()"
                      v-if="
                        permissions.includes('AUTHORISE_PAYROLL') &&
                        this.selectedLocations.length > 0
                      "
                    >
                      Authorise Selected
                    </b-button>
                    <b-button
                      style="margin: 10px"
                      variant="primary"
                      size="sm"
                      @click="$refs['unconfirm-routine'].show()"
                      v-if="
                        permissions.includes('AUTHORISE_PAYROLL') &&
                        this.selectedLocations.length > 0
                      "
                    >
                      Un-confirm Selection
                    </b-button>
                  </div>
                </div>
                <div v-else>
                  <p class="text-center my-5">
                    Populating report table, please wait...
                  </p>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div v-else class="alert alert-info">-->
      <!--        The payroll routine for this payroll period-->
      <!--        <b> ({{ (parseInt(pmyMonth) - 1) | getMonth }} {{ pmyYear }})</b> hasn't-->
      <!--        been run for any location.-->
      <!--      </div>-->
    </div>

    <b-modal
      ref="authorise-routine"
      title="Authorise Payroll Routine"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="modal-body p-1">
        <p>
          Are you sure you want to authorise the payroll routine for the
          selected location(s)?
        </p>

        <label for="">Comment</label>
        <b-form-group>
          <textarea
            type="text"
            rows="4"
            class="form-control"
            v-model="comment"
          />
        </b-form-group>

        <b-button class="btn btn-success mt-2" @click="authoriseSelected">
          <i class="mdi mdi-check mr-2"></i>
          Authorise Routine
        </b-button>
      </div>
    </b-modal>



    <b-modal
      ref="unconfirm-routine"
      title="Unconfirm Payroll Routine"
      size="sm"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="modal-body p-1">
        <p>Are you sure you want to un-confirm this payroll routine?</p>

        <b-button class="btn btn-danger mt-2" @click="unconfirmRoutine">
          <i class="mdi mdi-undo-variant mr-2"></i>
          Un-confirm Routine
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>
